/*
* Copyright 2021, IntraLinks, Inc. All rights reserved.
*/
import * as React from 'react';
import _ from 'lodash';
import {
  IlButton, IlIcon, IlSelect, IlSelectItem, IlTextInput
} from 'il-framework-component';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import { getProfileAction } from '@intralinks/apps-core';
import parse from 'html-react-parser';
import * as styles from './ProfileInfo.scss';
import { I18nNamespaces, NUMBER_REGEX } from '../../../app/shared/utils/constants.util';
import {
  DUPLICATE_TIMEZONES,
  fetchTimeZone,
  LANGUAGE_TYPES,
  PHONE_TYPES,
  TIMEZONES_MAPPINGS,
  USER_INDUSTRIES,
  USER_TITLES
} from '../../../app/shared/utils/profile.util';
import countries from '../../../app/shared/utils/country-list.json';
import { AppDispatch, RootState } from '../../../app/store';
import { getUpdateProfile } from '../profileSlice';
import { IProfileProperties } from '../../../domain/models/IProfile';
import { IProfileApps } from '../../../domain/models/IProfileApps';

interface IProfileState {
  toggle: boolean;
  stateUpdate: boolean;
  phone: IPhoneDetails[];
  firstname: string;
  lastname: string;
  email: string;
  displayName: string;
  locale: string;
  timezone: string;
  id: string;
  invalidFirstName: boolean;
  invalidLastName: boolean;
  address: IAddressDetails[];
  disabled: boolean;
  title: string;
  industry: string;
}

interface IPhoneDetails {
  type: string;
  number: string;
}

interface IAddressDetails {
  street1: string;
  street2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  type: string;
}

interface IProfileProps extends WithTranslation, IDispatchProps, IStateProps {
}

class PersonalInfo extends React.Component<IProfileProps, IProfileState> {
  constructor(props: IProfileProps) {
    super(props);
    this.state = {
      id: '',
      firstname: '',
      lastname: '',
      toggle: true,
      stateUpdate: false,
      displayName: '',
      phone: [],
      email: '',
      locale: '',
      timezone: '',
      invalidFirstName: false,
      invalidLastName: false,
      address: [],
      disabled: true,
      title: '',
      industry: ''
    };
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (!prevState.stateUpdate && nextProps.profileDetails.id !== '') {
      return {
        ...prevState,
        phone: nextProps.profileDetails.phoneInfos === undefined ? [] : nextProps.profileDetails.phoneInfos,
        stateUpdate: true,
        firstname: nextProps.profileDetails.name?.firstName || '',
        lastname: nextProps.profileDetails.name?.lastName || '',
        email: nextProps.profileDetails.externalId,
        displayName: nextProps.profileDetails.name?.displayName || '',
        address: nextProps.profileDetails.addresses !== undefined ? nextProps.profileDetails.addresses : [],
        locale: nextProps.profileDetails.preferences === undefined ? null : nextProps.profileDetails.preferences.locale,
        timezone: nextProps.profileDetails.preferences === undefined ? null : nextProps.profileDetails.preferences.timezone,
        title: nextProps.profileDetails.title === undefined ? 'UNAVAILABLE' : nextProps.profileDetails.title,
        industry: nextProps.profileDetails.industry === undefined ? 'UNAVAILABLE' : nextProps.profileDetails.industry
      };
    }
    return null;
  };

  onCancel = () => {
    this.setState(
      { toggle: true, stateUpdate: false }
    );
  };

  // Removing the phone info if the "type" "number" is empty in the object array
  getPhoneReqData = (phoneData: IPhoneDetails[]): IPhoneDetails[] => (
    phoneData.filter((phone) => (!_.isEmpty(phone.type) && !_.isEmpty(phone.number))));

  onSubmit = async (): Promise<void> => {
    const { ...state } = this.state;
    const {
      dispatchUpdateProfile, profileDetails, t, dispatchAppsCoreProfile
    } = this.props;
    const phoneReqData: IPhoneDetails[] = state.phone && state.phone.length > 0 ? this.getPhoneReqData(state.phone) : state.phone;
    const addressData = [...state.address];
    let updateProfileObj: any = {};
    if (!_.isEqual(state.phone, profileDetails.phoneInfos)) updateProfileObj = _.extend(updateProfileObj, { phoneInfos: phoneReqData });
    if (!profileDetails.name || !profileDetails.name.firstName || !profileDetails.name.lastName
      || !_.isEqual(profileDetails.name.firstName, state.firstname) || !_.isEqual(profileDetails.name.lastName, state.lastname)) {
      updateProfileObj = _.extend(updateProfileObj, {
        name: {
          firstName: state.firstname,
          lastName: state.lastname,
          displayName: `${state.firstname} ${state.lastname}`
        }
      });
    }
    const preferences: any = {};
    if (!_.isEqual(state.locale, profileDetails.preferences.locale)) { preferences.locale = state.locale; }
    if (!_.isEqual(state.timezone, profileDetails.preferences.timezone)) { preferences.timezone = state.timezone; }
    if (!_.isEmpty(preferences)) updateProfileObj = _.extend(updateProfileObj, { preferences });
    if (!_.isEqual(state.title, profileDetails.title)) updateProfileObj = _.extend(updateProfileObj, { title: state.title });
    if (!_.isEqual(state.industry, profileDetails.industry)) updateProfileObj = _.extend(updateProfileObj, { industry: state.industry });
    const addressValues = _.omitBy(addressData[0], _.isEmpty);
    if (!_.isEmpty(addressValues)) {
      addressValues.type = addressValues.type || 'HOME';
      addressValues.preferred = addressValues.preferred || false;
      if (!_.isEqual(addressValues, profileDetails.addresses?.[0])) updateProfileObj.addresses = [addressValues];
    }
    if (!_.isEmpty(updateProfileObj)) {
      await dispatchUpdateProfile(updateProfileObj, t);
      await dispatchAppsCoreProfile();
    }
    this.setState(
      { toggle: true, stateUpdate: false }
    );
  };

  nameValidation = () => {
    const { firstname, lastname } = this.state;
    if (!(firstname) || firstname === '') {
      this.setState({ invalidFirstName: true });
    }
    if (!(lastname) || lastname === '') {
      this.setState({ invalidLastName: true });
    }
    if (firstname && lastname) {
      this.setState({ invalidLastName: false, invalidFirstName: false });
    }
  };

  editProfile = () => {
    this.setState({ toggle: false, stateUpdate: false, disabled: true });
    this.nameValidation();
  };

  updateForm = (e: any, index: number) => {
    const { address } = this.state;
    const { id, value } = e.target;
    const updatedObj = { ...address[index], [id]: value };
    if (value) {
      this.setState({ disabled: false });
    }
    this.setState({
      address: [
        ...address.slice(0, index),
        updatedObj,
        ...address.slice(index + 1)
      ]
    });
  };

  addRow = () => {
    const { phone } = this.state;
    this.setState({ phone: [...phone, { type: '', number: '' }] });
  };

  updateFormData = (e: any, rowNumber: number) => {
    const { phone } = this.state;
    const { id } = e.target;
    let { value } = e.target;
    if (id === 'phone-number') {
      value = e.target.value.replace(NUMBER_REGEX, '');
    }
    const key = (id === 'type' ? 'type' : 'number');
    const updatedObj = { ...phone[rowNumber], [key]: value, preferred: true };
    if (!(updatedObj.type && updatedObj.number)) {
      this.setState({ disabled: true });
    } else {
      this.setState({ disabled: false });
    }
    this.setState({
      phone: [
        ...phone.slice(0, rowNumber),
        updatedObj,
        ...phone.slice(rowNumber + 1)
      ]
    });
  };

  updateFields = (e: any) => {
    const { id } = e.target;
    const { value } = e.target;
    const { firstname, lastname } = this.state;

    if (!value || value === '') {
      if (id === 'firstname') {
        this.setState({ invalidFirstName: true });
      }
      if (id === 'lastname') {
        this.setState({ invalidLastName: true });
      }
    } else if (id === 'firstname') {
      this.setState({ invalidFirstName: false });
    } else if (id === 'lastname') {
      this.setState({ invalidLastName: false });
    }
    const key = (id === 'firstname' ? 'firstname' : 'lastname');

    this.setState({ [key]: (value ?? '').trim() } as IProfileState);
    if (!(firstname && lastname)) {
      this.setState({ disabled: true });
    } else {
      this.setState({ disabled: false });
    }
  };

  inputRender = () => {
    const { phone } = this.state;
    this.renderphoneFileds(phone);
    this.addRow();
  };

  deleteRow = (e: any, rowNumber: any) => {
    const { phone } = this.state;
    const phoneData = [...phone];
    phoneData.splice(rowNumber, 1);
    phoneData.slice(rowNumber - 1);
    this.setState({ phone: phoneData, disabled: false });
  };

  renderLocaleInfo = (locale: string) => LANGUAGE_TYPES[locale];

  renderProfileInfoTimeZone = (timezone: string) => TIMEZONES_MAPPINGS[timezone] || TIMEZONES_MAPPINGS[DUPLICATE_TIMEZONES[timezone]];

  renderTitle = (title: string) => USER_TITLES[title];

  renderIndustry = (industry: string) => USER_INDUSTRIES[industry];

  renderPhoneType = (phoneType: string) => PHONE_TYPES[phoneType];

  renderCountrytype = (countryType: string) => {
    const { t } = this.props;
    return `${t(countryType, { ns: I18nNamespaces.COUNTRIES_NAMES })}`;
  };

  getProfileDetails =(): JSX.Element => {
    const { profileDetails } = this.props;
    return (
      <>
        {profileDetails.name !== undefined
          ? (
            <h2 className={styles.profileInfoHeader} id='testProfileName'>
              {profileDetails.name.firstName} {profileDetails.name.lastName}
            </h2>
          ) : null }
      </>
    );
  };

  getLocale = (): JSX.Element => {
    const { profileDetails, t } = this.props;
    return (
      <>
        {profileDetails.preferences !== undefined ? (
          <div className={styles.profileEdit}>
            <h1 className={styles.profileInfoTitle2}>{t('profileInformation.language', { ns: I18nNamespaces.PROFILE })}</h1>
            <p className={styles.profileCls} id='testProfileLocale'>{this.renderLocaleInfo(profileDetails.preferences.locale)}</p>
          </div>
        ) : null}
      </>
    );
  };

  getTimezone = (): JSX.Element => {
    const { profileDetails, t } = this.props;
    return (
      <>
        {profileDetails.preferences !== undefined ? (
          <div className={styles.profileEdit}>
            <h1 className={styles.profileTimezone}>{t('profileInformation.timezone', { ns: I18nNamespaces.PROFILE })}</h1>
            <p className={styles.profileCls2} id='testProfileTimezone'>
              {this.renderProfileInfoTimeZone(profileDetails.preferences.timezone)}
            </p>
          </div>
        ) : null }
      </>
    );
  };

  getPhoneDetails = (): JSX.Element => {
    const { profileDetails, t } = this.props;
    return (
      <>
        {profileDetails.phoneInfos !== undefined
          ? (
            <div className={styles.profilePhoneEdit}>
              <h1 className={styles.profileInfoTitle3}>{t('profileInformation.phone', { ns: I18nNamespaces.PROFILE })}</h1>
              {profileDetails.phoneInfos.map((phone, index) => (
                <p id='testProfilePhoneNumber' className={styles.profileClsnumber} key={_.uniqueId(`${index}`)}>
                  {phone.number} ({this.renderPhoneType(phone.type)})
                </p>
              ))}
            </div>
          ) : null }
      </>
    );
  };

  getAddressDetails = (): JSX.Element => {
    const { profileDetails, t } = this.props;
    const addressData = _.isEmpty(profileDetails.addresses) ? {} : _.omit(profileDetails.addresses[0], ['preferred', 'type', 'region']);
    return (
      <>
        { !_.isEmpty(addressData) ? (
          <div className={styles.profilePhoneEdit}>
            <h1 className={styles.profileInfoTitle4}>{t('profileInformation.address', { ns: I18nNamespaces.PROFILE })}</h1>
            <><p id='testProfileStreet1' className={styles.profileAddressFields}>{addressData.street1}</p>
              <p id='testProfileFields' className={styles.profileAddressFields}>{this.renderAddressData(addressData)}</p>
              <p id='testProfileCountry' className={styles.profileAddressFields}>{this.renderCountrytype(addressData.country)}</p>
            </>
          </div>
        ) : null }
      </>
    );
  };

  dropdownValue(e: any) {
    const { id, value } = e.target;
    if (!value) {
      this.setState({ disabled: true });
    } else {
      this.setState({ disabled: false });
    }
    this.setState({ [id]: value } as IProfileState);
  }

  renderAddressData = (address: any) => {
    let result = '';
    _.keys(address).forEach((key: any) => {
      if (key !== 'street1' && key !== 'street2' && key !== 'type' && key !== 'preferred' && key !== 'country' && address[key]) {
        result += `${address[key]}, `;
      }
    });
    return result.substr(0, result.length - 2);
  };

  renderphoneFileds = (phone: IPhoneDetails[]) => {
    const { t } = this.props;
    return (
      phone.map((phoneObj: any, index: number) => {
        const { type } = phoneObj as IPhoneDetails;
        return (
          <div className={styles.addPhoneSection} key={`${index + 1}`}>
            <div className={styles.phoneTypeField}>
              <IlSelect
                id='type'
                name='type'
                value={phoneObj.type}
                labelText={t('profileInformation.type', { ns: I18nNamespaces.PROFILE })}
                onChange={(e) => this.updateFormData(e, index)}
              >
                <IlSelectItem
                  text='Select'
                />
                {_.map(PHONE_TYPES, (value, key) => (
                  <IlSelectItem
                    key={key}
                    value={key}
                    text={value}
                  />
                ))}
              </IlSelect>
            </div>
            <div className={styles.contactField}>
              <IlTextInput
                id='phone-number'
                labelText={t('profileInformation.phoneNumber', { ns: I18nNamespaces.PROFILE })}
                value={!type ? '' : phoneObj.number}
                disabled={!type}
                placeholder={t('profileInformation.number_placeholder', { ns: I18nNamespaces.PROFILE })}
                onChange={(e) => this.updateFormData(e, index)}
              />
            </div>
            <div className={styles.iconsStyle}>
              <IlButton
                className={classNames(`${styles.iconHide}`, `${styles.profile_edit_phoneDeleteBtn}`)}
                kind='secondary'
                type='button'
                onClick={(e) => this.deleteRow(e, index)}
              ><IlIcon name='delete-v1' className={styles['profile_edit_phone-delete']} />
              </IlButton>
              <IlButton
                className={styles.buttonHide}
                kind='secondary'
                type='button'
                onClick={(e) => this.deleteRow(e, index)}
              >Delete
              </IlButton>
            </div>
          </div>
        );
      })
    );
  };

  renderAddress = (address: IAddressDetails[]) => {
    const { t } = this.props;
    if (address.length < 1) {
      this.setState({
        address: [...address, {
          street1: '',
          street2: '',
          city: '',
          state: '',
          country: '',
          postalCode: '',
          type: ''
        }]
      });
    }
    return (
      address.map((addressData, index: number) => (
        <div key={`${index + 1}`}>
          <div className={styles.addressFiled}>
            <IlTextInput
              id='street1'
              placeholder={t('profileInformation.placeHolder', { ns: I18nNamespaces.PROFILE })}
              labelText={t('profileInformation.address1', { ns: I18nNamespaces.PROFILE })}
              onChange={(e) => this.updateForm(e, index)}
              value={addressData?.street1}
            />
          </div>
          <div className={styles.addressFiled2}>
            <IlTextInput
              id='street2'
              placeholder={t('profileInformation.placeHolder', { ns: I18nNamespaces.PROFILE })}
              labelText={t('profileInformation.address2', { ns: I18nNamespaces.PROFILE })}
              onChange={(e) => this.updateForm(e, index)}
              value={addressData?.street2}
            />
          </div>
          <div className={styles.addressFields}>
            <div className={styles.cityFields}>
              <IlTextInput
                id='city'
                labelText={t('profileInformation.city', { ns: I18nNamespaces.PROFILE })}
                onChange={(e) => this.updateForm(e, index)}
                value={addressData?.city}
              />
            </div>
            <div className={styles.stateFields}>
              <IlTextInput
                id='state'
                labelText={t('profileInformation.state', { ns: I18nNamespaces.PROFILE })}
                onChange={(e) => this.updateForm(e, index)}
                value={addressData?.state}
              />
            </div>
          </div>
          <div className={styles.postalAddressSection}>
            <div className={styles.postalInput}>
              <IlTextInput
                id='postalCode'
                labelText={t('profileInformation.postalCode', { ns: I18nNamespaces.PROFILE })}
                onChange={(e) => this.updateForm(e, index)}
                value={addressData?.postalCode}
              />
            </div>
            <div className={styles.selectFields3}>
              <IlSelect
                id='country'
                name='country'
                labelText={t('profileInformation.country', { ns: I18nNamespaces.PROFILE })}
                className={styles.selectFields}
                onChange={(e) => this.updateForm(e, index)}
                value={addressData?.country}
              >
                <IlSelectItem
                  text='Select'
                  value='0'
                />
                {countries.map((country) => (
                  <IlSelectItem
                    key={country.code}
                    value={country.name}
                    text={`${t(country.name, { ns: I18nNamespaces.COUNTRIES_NAMES })}`}
                  />
                ))}
              </IlSelect>
            </div>
          </div>
        </div>
      ))
    );
  };

  renderContactInfo = (): JSX.Element => {
    const { ...state } = this.state;
    const { t } = this.props;
    const phoneTitle = state.phone && state.phone.length > 0
      ? `${t('profileInformation.addPhone')}` : `${t('profileInformation.addNewPhone')}`;
    const buttonDisabled = !(state.firstname && state.lastname);
    return (
      <div>
        <form>
          <div className={styles.profileInfo}>
            <div className={styles.profileTitleCls}>
              <p className={styles.profileInfoTitleHeader}>{t('profileInformation.personalInfo', { ns: I18nNamespaces.PROFILE })}</p>
              <div className={styles.editProfileButtonsDesktop}>
                <div className={styles.buttonCancel}>
                  <IlButton
                    id='cancel'
                    kind='secondary'
                    type='button'
                    onClick={this.onCancel}
                  >{t('profileInformation.cancel', { ns: I18nNamespaces.PROFILE })}
                  </IlButton>
                </div>
                <div className={styles.buttonSave}>
                  <IlButton
                    id='save'
                    kind='primary'
                    type='button'
                    onClick={this.onSubmit}
                    disabled={state.disabled || buttonDisabled}
                  >{t('profileInformation.save', { ns: I18nNamespaces.PROFILE })}
                  </IlButton>
                </div>
              </div>
            </div>
            <div className={styles.editScreen}>
              <div className={classNames(styles.emailInput)}>
                <IlTextInput
                  id='email'
                  labelText={t('profileInformation.email', { ns: I18nNamespaces.PROFILE })}
                  value={state.email}
                  disabled
                />
              </div>
              <div className={styles.profileNameInputContainer}>
                <div className={styles.firstInput}>
                  <IlTextInput
                    id='firstname'
                    placeholder={t('profileInformation.firstname', { ns: I18nNamespaces.PROFILE })}
                    labelText={t('profileInformation.firstname', { ns: I18nNamespaces.PROFILE })}
                    value={state.firstname}
                    invalidText={t('profileInformation.invalid_text', { ns: I18nNamespaces.PROFILE })}
                    invalid={state.invalidFirstName}
                    onChange={(e) => this.updateFields(e)}
                    onBlur={(e) => this.updateFields(e)}
                  />
                </div>
                <div className={styles.secondInput}>
                  <IlTextInput
                    id='lastname'
                    placeholder={t('profileInformation.lastname', { ns: I18nNamespaces.PROFILE })}
                    labelText={t('profileInformation.lastname', { ns: I18nNamespaces.PROFILE })}
                    value={state.lastname}
                    invalidText={t('profileInformation.invalid_text', { ns: I18nNamespaces.PROFILE })}
                    invalid={state.invalidLastName}
                    onChange={(e) => this.updateFields(e)}
                    onBlur={(e) => this.updateFields(e)}
                  />
                </div>
              </div>
              <div className={styles.dropDownTitle}>
                <div className={styles.titleSelectField}>
                  <IlSelect
                    id='title'
                    onChange={(e) => this.dropdownValue(e)}
                    labelText={t('profileInformation.title', { ns: I18nNamespaces.PROFILE })}
                    className={styles.selectFields}
                    value={state.title}
                  >
                    {_.map(USER_TITLES, (value, key) => (
                      <IlSelectItem
                        key={key}
                        value={key}
                        text={value}
                      />
                    ))}
                  </IlSelect>
                </div>
                <div className={styles.selectFields2}>
                  <IlSelect
                    id='industry'
                    labelText={t('profileInformation.industry', { ns: I18nNamespaces.PROFILE })}
                    className={styles.selectFields}
                    onChange={(e) => this.dropdownValue(e)}
                    value={state.industry}
                  >
                    {_.map(USER_INDUSTRIES, (value, key) => (
                      <IlSelectItem
                        key={key}
                        value={key}
                        text={value}
                      />
                    ))}
                  </IlSelect>
                </div>
              </div>
              <div className={styles.dropDownFields}>
                <div className={styles.selectFieldLocale}>
                  <IlSelect
                    id='locale'
                    onChange={(e) => this.dropdownValue(e)}
                    labelText={t('profileInformation.titleLanguage', { ns: I18nNamespaces.PROFILE })}
                    className={styles.selectFields}
                    value={state.locale}
                  >
                    <IlSelectItem
                      text='Select'
                      value='0'
                    />
                    {_.map(LANGUAGE_TYPES, (value, key) => (
                      <IlSelectItem
                        key={key}
                        value={key}
                        text={value}
                      />
                    ))}
                  </IlSelect>
                </div>
                <div className={styles.selectFields2}>
                  <IlSelect
                    id='timezone'
                    labelText={t('profileInformation.titleTimezone', { ns: I18nNamespaces.PROFILE })}
                    className={styles.selectFields}
                    onChange={(e) => this.dropdownValue(e)}
                    value={fetchTimeZone(state.timezone) || state.timezone}
                  >
                    <IlSelectItem
                      text='Select'
                      value='0'
                    />
                    {_.map(TIMEZONES_MAPPINGS, (value, key) => (
                      <IlSelectItem
                        key={key}
                        value={key}
                        text={value}
                      />
                    ))}
                  </IlSelect>
                </div>
              </div>
              <p className={styles.phoneTitle}>{t('profileInformation.phone', { ns: I18nNamespaces.PROFILE })}</p>
              {state.phone ? this.renderphoneFileds(state.phone) : null}
              <div>
                <IlButton
                  id='addAnotherPhone'
                  kind='secondary'
                  type='button'
                  onClick={() => this.inputRender()}
                >{phoneTitle}
                </IlButton>
              </div>
              <p className={styles.addressTitle}>{t('profileInformation.address', { ns: I18nNamespaces.PROFILE })}</p>
              {state.address ? this.renderAddress(_.take(state.address, 1)) : null }
            </div>
            <div className={styles.editProfileButtonsMobile}>
              <div className={styles.buttonCancel}>
                <IlButton
                  id='cancelMobile'
                  kind='secondary'
                  type='button'
                  onClick={this.onCancel}
                >{t('profileInformation.cancel', { ns: I18nNamespaces.PROFILE })}
                </IlButton>
              </div>
              <div className={styles.buttonSave}>
                <IlButton
                  id='saveMobile'
                  kind='primary'
                  type='button'
                  onClick={this.onSubmit}
                  disabled={state.disabled || buttonDisabled}
                >{t('profileInformation.save', { ns: I18nNamespaces.PROFILE })}
                </IlButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  renderEntitledApps = (): JSX.Element => {
    const { applications, t } = this.props;
    return (
      <div className={styles.entitledAppsContainer}>
        <span className={styles.entitledAppsTitle} id='entitledTitle'>
          {t('entitledApps.title')}
        </span>
        <div className={styles.entitledApps}>
          {applications.map((app) => {
            const validDisplayText = app.displayText ? parse(app.displayText) : '';
            const appName = _.isEmpty(validDisplayText) ? app.name : validDisplayText;

            return (
              <a href={app.url} id={`test${appName}`} className={styles.entitledApp} title={app.name} tabIndex={0}>
                <span className={styles.entitledAppTitle}>
                  {appName}
                </span>
                <span className={styles.entitledAppDescription}>
                  {app.description ? app.description : ''}
                </span>
                <IlIcon name='chevron-right' className={styles.entitledAppIcon} />
              </a>
            );
          })}
        </div>
      </div>
    );
  };

  render(): JSX.Element {
    const { t, profileDetails } = this.props;
    const { toggle } = this.state;
    return (
      <div>
        {this.renderEntitledApps()}
        <div>
          { toggle
            ? (
              <form>
                <div className={styles.profileInfo}>
                  <div className={styles.profileTitleCls}>
                    <div className={styles.profileInfoTitleHeader}>
                      {t('profileInformation.personalInfo', { ns: I18nNamespaces.PROFILE })}
                    </div>
                    {/* Desktop version edit profile button - render based on responsive design later */}
                    <div className={styles.editProfileBtn}>
                      <IlButton
                        id='edit'
                        kind='primary'
                        type='button'
                        onClick={() => this.editProfile()}
                      >{t('profileInformation.edit', { ns: I18nNamespaces.PROFILE })}
                      </IlButton>
                    </div>
                  </div>
                  <div className={styles.profileInfoCls}>
                    <p className={styles.profileEmailCls} id='testProfileEmail'>{profileDetails.externalId}</p>
                    {this.getProfileDetails()}
                  </div>
                  {this.getLocale()}
                  {this.getTimezone()}
                  {this.getPhoneDetails()}
                  {this.getAddressDetails()}
                  {/* Mobile version edit profile button - render based on responsive design later */}
                  <div className={styles.editProfileBtnMobile}>
                    <IlButton
                      id='editMobile'
                      kind='primary'
                      type='button'
                      onClick={() => this.editProfile()}
                    >{t('profileInformation.edit', { ns: I18nNamespaces.PROFILE })}
                    </IlButton>
                  </div>
                </div>
              </form>
            ) : this.renderContactInfo() }
        </div>
      </div>
    );
  };
}

interface IStateProps {
  profileDetails: IProfileProperties;
  applications: IProfileApps[];
}

interface IDispatchProps {
  dispatchUpdateProfile: (updateProfileObj: any, t: TFunction) => void;
  dispatchAppsCoreProfile: () => void;
}

const mapStateToProps = (state: RootState): IStateProps => {
  const { profile: { profileDetails, applications } } = state;
  return {
    profileDetails,
    applications
  };
};

const mapDispatchToProps = (dispatch: AppDispatch): IDispatchProps => ({
  dispatchUpdateProfile: (updateProfileObj: any, t): void => dispatch(getUpdateProfile(updateProfileObj, t)),
  dispatchAppsCoreProfile: (): void => dispatch(getProfileAction())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation([I18nNamespaces.PROFILE, I18nNamespaces.DEFAULT, I18nNamespaces.ERROR, I18nNamespaces.COUNTRIES_NAMES])(PersonalInfo));
